<template>
  <div id="wrapper" v-show="!rotating">
    <div class="header">
      <img class="logo" src="./imgs/astig_logo.png" />
      <div class="text">
        <div style="font-weight: bold; color: #333; font-size: 1rem">
          <span>配戴安视优散光隐形眼镜所获得的</span><span class="color">超清视觉（1-3）</span
          ><span>会是什么样？</span>
        </div>
        <div class="grey">赶快拖动下方圆圈去探索散光完全矫正后不同场景下的视觉新体验。</div>
      </div>
    </div>
    <div class="body_wrapper">
      <div class="body">
        <img
          v-show="rightButton == 1 && zhujing == 1 && zhouwei == 1"
          class="far_day bg111"
          src="./imgs/near_cyl75_axis0.jpg"
        />
        <img
          v-show="rightButton == 1 && zhujing == 1 && zhouwei == 2"
          class="far_day bg112"
          src="./imgs/near_cyl75_axis90.jpg"
        />
        <img
          v-show="rightButton == 1 && zhujing == 1 && zhouwei == 3"
          class="far_day bg113"
          src="./imgs/near_cyl75_axis45.jpg"
        />
        <img
          v-show="rightButton == 1 && zhujing == 2 && zhouwei == 1"
          class="far_day bg121"
          src="./imgs/near_cyl125_axis0.jpg"
        />
        <img
          v-show="rightButton == 1 && zhujing == 2 && zhouwei == 2"
          class="far_day bg122"
          src="./imgs/near_cyl125_axis90.jpg"
        />
        <img
          v-show="rightButton == 1 && zhujing == 2 && zhouwei == 3"
          class="far_day bg123"
          src="./imgs/near_cyl125_axis45.jpg"
        />
        <img
          v-show="rightButton == 2 && zhujing == 1 && zhouwei == 1"
          class="far_day bg211"
          src="./imgs/far_night_cyl75_axis0.jpg"
        />
        <img
          v-show="rightButton == 2 && zhujing == 1 && zhouwei == 2"
          class="far_day bg211"
          src="./imgs/far_night_cyl75_axis90.jpg"
        />
        <img
          v-show="rightButton == 2 && zhujing == 1 && zhouwei == 3"
          class="far_day bg211"
          src="./imgs/far_night_cyl75_axis45.jpg"
        />
        <img
          v-show="rightButton == 2 && zhujing == 2 && zhouwei == 1"
          class="far_day bg211"
          src="./imgs/far_night_cyl125_axis0.jpg"
        />
        <img
          v-show="rightButton == 2 && zhujing == 2 && zhouwei == 2"
          class="far_day bg211"
          src="./imgs/far_night_cyl125_axis90.jpg"
        />
        <img
          v-show="rightButton == 2 && zhujing == 2 && zhouwei == 3"
          class="far_day bg211"
          src="./imgs/far_night_cyl125_axis45.jpg"
        />
        <img
          v-show="rightButton == 3 && zhujing == 1 && zhouwei == 1"
          class="far_day bg311"
          src="./imgs/far_day_cyl75_axis0.jpg"
        />
        <img
          v-show="rightButton == 3 && zhujing == 1 && zhouwei == 2"
          class="far_day bg311"
          src="./imgs/far_day_cyl75_axis90.jpg"
        />
        <img
          v-show="rightButton == 3 && zhujing == 1 && zhouwei == 3"
          class="far_day bg311"
          src="./imgs/far_day_cyl75_axis45.jpg"
        />
        <img
          v-show="rightButton == 3 && zhujing == 2 && zhouwei == 1"
          class="far_day bg311"
          src="./imgs/far_day_cyl125_axis0.jpg"
        />
        <img
          v-show="rightButton == 3 && zhujing == 2 && zhouwei == 2"
          class="far_day bg311"
          src="./imgs/far_day_cyl125_axis90.jpg"
        />
        <img
          v-show="rightButton == 3 && zhujing == 2 && zhouwei == 3"
          class="far_day bg311"
          src="./imgs/far_day_cyl125_axis45.jpg"
        />

        <canvas id="canvas"></canvas>
        <!-- 前景图片 -->
        <div class="fg-wrapper">
          <img v-show="rightButton == 1" class="foreground foreground_1" src="./imgs/near.jpg" />
          <img
            v-show="rightButton == 2"
            class="foreground foreground_2"
            src="./imgs/far_night.jpg"
          />
          <img v-show="rightButton == 3" class="foreground foreground_3" src="./imgs/far_day.jpg" />
        </div>
        <!-- 图片黑色遮罩 -->
        <div class="fg_mask" v-show="type === 2"></div>
        <!-- 圆形提示 -->
        <div class="circle_desc" v-show="type === 2">
          <div>请先确认</div>
          <div>左侧处方后，再</div>
          <div>继续体验</div>
          <img src="./imgs/箭头.png" />
        </div>
      </div>
      <div class="button">
        <div
          class="button-item button-item1"
          :class="rightButton === 1 ? 'active' : ''"
          @click="onClickRightButton(1)"
        >
          <div class="text">
            <div class="t">作</div>
            <div class="t">工</div>
            <div class="t">离</div>
            <div class="t">距</div>
            <div class="t">近</div>
          </div>
        </div>
        <div
          class="button-item button-item2"
          :class="rightButton === 2 ? 'active' : ''"
          @click="onClickRightButton(2)"
        >
          <div class="text">
            <div class="t">间</div>
            <div class="t">夜</div>
          </div>
        </div>
        <div
          class="button-item button-item3"
          :class="rightButton === 3 ? 'active' : ''"
          @click="onClickRightButton(3)"
        >
          <div class="text">
            <div class="t">天</div>
            <div class="t">白</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="left">
        <img class="footer_img" src="./imgs/footer_text.png" style="" />
        <!-- <div>*一项对安视优欧舒适散光日抛型隐形眼镜进行的双边、受试者屏蔽、随机、交叉研究结果发现其视力表现相较于球镜矫正的最佳视力有所提高。</div> -->
      </div>
      <div class="reference" @click="onClickReference">参考文献</div>
    </div>
    <div class="panel" v-show="type === 2">
      <div style="display: flex; align-items: center; margin: 1rem 1.2rem 0.3rem 1.2rem">
        <img src="./imgs/chufang.png" style="height: 1.6rem; margin-right: 0.6rem" />
        <div style="color: #fff; font-size: 1.6rem; font-weight: bold">处方</div>
      </div>
      <div
        style="
          color: #fff;
          font-size: 1.2rem;
          margin: 0 1.2rem;
          margin-top: 0.4rem;
          letter-spacing: 0;
        "
      >
        请选择您想要的散光隐形眼镜处方
      </div>
      <div
        style="
          color: #fff;
          font-size: 1.2rem;
          margin: 0 1.2rem;
          margin-bottom: 1rem;
          letter-spacing: 0;
        "
      >
        进行视觉模拟体验。
      </div>
      <div class="zhujing">
        <div class="label">柱镜：</div>
        <div
          class="panel_button panel_button_zhujing"
          :class="zhujing === 1 ? 'active' : ''"
          @click="onClickZhujing(1)"
        >
          -0.75DC
        </div>
        <div
          class="panel_button panel_button_zhujing"
          :class="zhujing === 2 ? 'active' : ''"
          @click="onClickZhujing(2)"
        >
          -1.25DC
        </div>
      </div>
      <div class="zhouwei">
        <div class="label">轴位：</div>
        <div
          class="panel_button panel_button_zhouwei"
          :class="zhouwei === 1 ? 'active' : ''"
          @click="onClickZhouwei(1)"
        >
          180°
        </div>
        <div
          class="panel_button panel_button_zhouwei"
          :class="zhouwei === 2 ? 'active' : ''"
          @click="onClickZhouwei(2)"
        >
          90°
        </div>
        <div
          class="panel_button panel_button_zhouwei"
          :class="zhouwei === 3 ? 'active' : ''"
          @click="onClickZhouwei(3)"
        >
          45°
        </div>
      </div>
      <div class="line"></div>
      <div style="display: flex; justify-content: center">
        <div class="confirm" @click="onConfirm(1)">确认</div>
      </div>
    </div>
    <div class="panel_2" v-show="type === 1">
      <img src="./imgs/chufang.png" style="height: 1.8rem; margin-right: 0.6rem" />
      <div class="panel_button">{{ zhujingarr[zhujing - 1] }}</div>
      <div class="panel_button">{{ zhouweiarr[zhouwei - 1] }}</div>
      <div class="panel_button2" @click="onConfirm(2)">重置</div>
    </div>
  </div>
  <div class="dialog" v-show="showDialog" @click="showDialog = false">
    <div class="dialog_box" @click.stop>
      <div class="box_1">
        <div>1-</div>
        <div>
          Cox S, Berntsen D, Bickle K, et al. Efficacy of toric contact lenses in fitting and
          patient-reported outcomes in contact lens wearers. Eye & Contact Lens: Sci & Clin Prac.
          2018;44(suppl 1):S296-S299
        </div>
      </div>
      <div class="box_1">
        <div>2-</div>
        <div>
          Berntsen D, Cox S. Bickle K et al. A randomized trial to evaluate the effect of toric
          versus spherical contact lenses on Vision & Eyestrain. Eye & Contact Lens: 2019; 45:1.
        </div>
      </div>
      <div class="box_1">
        <div>3-</div>
        <div>
          Black AA, Wood JM et al. The impact of uncorrected astigmatism on night driving
          performance. Ophthalmic Physiol Opt 2019;39:5 350-357.
        </div>
      </div>
      <img src="./imgs/close_round.png" @click="showDialog = false" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const zhujing = ref(1);
const zhouwei = ref(1);
const type = ref(2);
const zhujingarr = ref(["-0.75DC", "-1.25DC"]);
const zhouweiarr = ref(["180°", "90°", "45°"]);
const rightButton = ref(3);
const showDialog = ref(false);

const frontImg = ref();
const canvas = ref();
const ctx = ref();
const sx = ref();
const sy = ref();
const sw = ref();
const sh = ref();
const windowWidth = ref();
const windowHeight = ref();
const imgWidth = ref(1167);
const imgHeight = ref(1750);
const width = ref(0);
const height = ref(0);
const rotating = ref(false);
const touchX = ref(200);
const touchY = ref(200);
const circleSize = ref(70);
const isPortrait = ref(null);
const showPanel = ref(false);

const onClickZhujing = value => (zhujing.value = value);
const onClickZhouwei = value => (zhouwei.value = value);

const onConfirm = value => {
  type.value = value;
  if (value === 1) {
    draw();
  } else if (value === 2) {
    clearCanvas();
    init();
  }
};

const onClickRightButton = value => {
  rightButton.value = value;
  frontImg.value = document.querySelector(`.foreground_${value}`);
  clearCanvas();
  init();
  if (type.value == 1) draw();
};

// 判断横竖屏
function onOrientationchange(rotate) {
  if (window.orientation == 0 || window.orientation == 180) isPortrait.value = true;
  if (window.orientation == 90 || window.orientation == -90) isPortrait.value = false;
  if (rotate === "rotate") return;
  if (isPortrait.value) {
    const interval = setInterval(() => {
      rotating.value = true;
      if (window.innerWidth < window.innerHeight) {
        setTimeout(() => {
          isPortrait.value = true;
          rotating.value = false;
          setTimeout(() => {
            init();
            simulateCover();
            initCanvas();
            if (type.value === 1) draw();
          }, 20);
        }, 300);
        clearInterval(interval);
      }
    }, 5);
  } else if (!isPortrait.value) {
    const interval = setInterval(() => {
      rotating.value = true;
      if (window.innerWidth >= window.innerHeight) {
        setTimeout(() => {
          isPortrait.value = false;
          rotating.value = false;
          setTimeout(() => {
            init();
            simulateCover();
            initCanvas();
            if (type.value === 1) draw();
          }, 20);
        }, 300);
        clearInterval(interval);
      }
    }, 5);
  }
}
onOrientationchange("rotate");
window.addEventListener("orientationchange", onOrientationchange, false);

function load() {
  init();
  addListener();
  simulateCover();
  initCanvas();
}

document.addEventListener("gesturestart", function (event) {
  event.preventDefault();
});

// 初始化
function init() {
  frontImg.value = document.querySelector(`.foreground_${rightButton.value}`);
  canvas.value = document.getElementById("canvas");
  showPanel.value = false;

  if (isPortrait.value) {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
  } else {
    windowWidth.value = window.innerHeight;
    windowHeight.value = window.innerWidth;
  }

  const bodyDom = $("body");
  if (isPortrait.value) {
    bodyDom.width(windowWidth.value);
    bodyDom.height(windowHeight.value);
    bodyDom.css("top", 0);
    bodyDom.css("left", 0);
    bodyDom.css("transform", "none");
    bodyDom.css("transform-origin", "50% 50%");
  } else {
    bodyDom.width(windowWidth.value);
    bodyDom.height(windowHeight.value);
    bodyDom.css("top", (windowWidth.value - windowHeight.value) / 2);
    bodyDom.css("left", 0 - (windowWidth.value - windowHeight.value) / 2);
    bodyDom.css("transform", "rotate(-90deg)");
    bodyDom.css("transform-origin", "50% 50%");
  }

  document.querySelector("html").style.fontSize = `${windowWidth.value / 6}%`;

  const header_ratio = 0.13;
  const footer_ratio = 0.06;
  const circle_desc_ratio = 0.54; // 圆形提示占canvas宽度的比例

  // 设置图片区域宽度和margin，利用百分比来适应不同屏幕
  document.querySelector("#wrapper").style.width = windowWidth.value + "px";
  document.querySelector("#wrapper").style.height = windowHeight.value + "px";
  document.querySelector("#wrapper").style.padding = `${windowHeight.value * 0.05}px 0 ${
    windowHeight.value * 0.05
  }px 0`;

  document.querySelector(".body").style.width =
    Math.round(windowWidth.value * (1 - header_ratio - footer_ratio)) + "px";
  document.querySelector(".body").style.height = Math.round(windowHeight.value * 0.86) + "px";
  document.querySelector(".body").style.margin = `0 ${windowWidth.value * header_ratio}px 0 ${
    windowWidth.value * footer_ratio
  }px`;
  document.querySelector(".body").style.border =
    windowWidth.value < 700 ? "2px solid #00a3df" : "4px solid #00a3df";

  width.value = document.querySelector(".body").clientWidth;
  height.value = document.querySelector(".body").clientHeight;
  document.querySelector(".fg-wrapper").style.display = "none";
  document.querySelector(".button").style.width = windowWidth.value * 0.82 + "px";
  document.querySelector(".button").style.height = windowHeight.value * 0.04 + "px";
  document.querySelector(".button").style.margin = `0 ${windowWidth.value * header_ratio}px 0 ${
    windowWidth.value * footer_ratio
  }px`;
  document.querySelector(".button-item1").style.width = windowWidth.value * 0.22 + "px";
  document.querySelector(".button-item1").style.marginRight = windowWidth.value * 0.015 + "px";
  document.querySelector(".button-item2").style.width = windowWidth.value * 0.22 + "px";
  document.querySelector(".button-item2").style.marginRight = windowWidth.value * 0.015 + "px";
  document.querySelector(".button-item3").style.width = windowWidth.value * 0.22 + "px";
  setTimeout(() => {
    showPanel.value = true;
    document.querySelector(".panel").style.top = windowHeight.value * 0.08 + "px";
    document.querySelector(".panel").style.left =
      windowWidth.value -
      (document.querySelector(".body").clientWidth -
        document.querySelector(".panel").clientHeight) /
        2 -
      windowWidth.value * header_ratio +
      "px";
    document.querySelector(".panel_2").style.top = windowHeight.value * 0.08 + "px";
    document.querySelector(".panel_2").style.left =
      windowWidth.value - windowWidth.value * header_ratio - windowWidth.value * 0.04 + "px";
  }, 10);

  if (windowWidth.value < 700) {
    document.querySelector(".far_day").style.borderRadius = "18px";
    document.querySelector(".fg_mask").style.borderRadius = "18px";
  } else {
    document.querySelector(".far_day").style.borderRadius = "16px";
    document.querySelector(".fg_mask").style.borderRadius = "16px";
  }
  if (rightButton.value == 1) {
    document.querySelector(".fg_mask").style.backgroundColor = "rgba(0, 0, 0, 0.75)";
  } else {
    document.querySelector(".fg_mask").style.backgroundColor = "rgba(0, 0, 0, 0.47)";
  }

  document.querySelector(".header").style.width = windowHeight.value + "px";
  document.querySelector(".header").style.height = windowWidth.value * header_ratio + "px";
  document.querySelector(".header").style.top = 0;
  document.querySelector(".header").style.left = windowWidth.value + "px";
  document.querySelector(".header").style.padding = `0 ${windowHeight.value * 0.05}px 0 ${
    windowHeight.value * 0.05
  }px`;
  document.querySelector(".header").style["transform"] = "rotate(90deg)";
  document.querySelector(".header").style["-webkit-transform"] = "rotate(90deg)";
  document.querySelector(".header").style["-webkit-transform-origin"] = "0 0";
  document.querySelector(".header").style["transform-origin"] = "0 0";

  document.querySelector(".footer").style.width = windowHeight.value + "px";
  document.querySelector(".footer").style.height = windowWidth.value * footer_ratio + "px";
  document.querySelector(".footer").style.top = 0;
  document.querySelector(".footer").style.left = windowWidth.value * footer_ratio + "px";
  document.querySelector(".footer").style.padding = `0 ${windowHeight.value * 0.05}px 0 ${
    windowHeight.value * 0.05
  }px`;
  document.querySelector(".footer").style["transform"] = "rotate(90deg)";
  document.querySelector(".footer").style["-webkit-transform"] = "rotate(90deg)";
  document.querySelector(".footer").style["-webkit-transform-origin"] = "0 0";
  document.querySelector(".footer").style["transform-origin"] = "0 0";

  document.querySelector(".dialog_box").style.width = windowHeight.value * 0.8 + "px";

  // 计算放大镜半径
  circleSize.value = (width.value * circle_desc_ratio) / 2;

  touchX.value = width.value / 2;
  if (windowWidth.value < 700) {
    touchY.value = height.value - circleSize.value - windowHeight.value * header_ratio;
  } else {
    touchY.value = height.value - circleSize.value / 2 - windowHeight.value * 0.05 - 90;
  }

  document.querySelector(".circle_desc").style.width = width.value * circle_desc_ratio + "px";
  document.querySelector(".circle_desc").style.height = width.value * circle_desc_ratio + "px";

  if (windowWidth.value <= 700) {
    console.log(height.value, circleSize.value, windowHeight.value);
    console.log(width.value * circle_desc_ratio);
    document.querySelector(".circle_desc").style.top =
      touchY.value - (width.value * circle_desc_ratio) / 2 + "px";
  } else {
    document.querySelector(".circle_desc").style.top = touchY.value - circleSize.value + "px";
  }
  document.querySelector(".circle_desc").style.left =
    touchX.value - (width.value * circle_desc_ratio) / 2 + "px";
  document.querySelector(".circle_desc").style.borderRadius =
    (width.value * circle_desc_ratio) / 2 + "px";

  if (windowWidth.value <= 700) {
    document.querySelector(".footer_img").style.width = windowHeight.value * 0.5 + "px";
  } else {
    document.querySelector(".footer_img").style.width = windowHeight.value * 0.65 + "px";
  }
}

// 添加触摸监听
const addListener = () => {
  canvas.value.addEventListener("touchstart", onTouchStart);
  canvas.value.addEventListener("touchend", onTouchEnd);
};

// 获得触摸位置距离canvas左上角的坐标
function getCoordinateInCanvas(e) {
  const event = e || window.event;
  if (isPortrait.value) {
    touchX.value =
      event.changedTouches[0].clientX -
      document.querySelector(".body").getBoundingClientRect().left;
    touchY.value =
      event.changedTouches[0].clientY - document.querySelector(".body").getBoundingClientRect().top;
  } else {
    // 如果是横屏，利用算法重新计算一下在canvas上的坐标（canvas跟随body旋转了90度，坐标轴也旋转了90度）
    touchX.value =
      width.value -
      (event.changedTouches[0].clientY -
        document.querySelector(".body").getBoundingClientRect().top);
    touchY.value =
      event.changedTouches[0].clientX -
      document.querySelector(".body").getBoundingClientRect().left;
  }
}

// 手指按下
const onTouchStart = e => {
  getCoordinateInCanvas(e);
  console.log(touchX.value, touchY.value);
  canvas.value.addEventListener("touchmove", onTouchMove, false);
};

// 手指移动
const onTouchMove = e => {
  console.log(touchX.value, touchY.value);

  e.preventDefault();
  getCoordinateInCanvas(e);
  draw();
};

// 手指抬起
const onTouchEnd = () => {
  canvas.value.removeEventListener("touchmove", onTouchMove);
};

function initCanvas() {
  canvas.value = document.getElementById("canvas");
  if (canvas.value.getContext) {
    canvas.value.width = document.querySelector(".body").clientWidth;
    canvas.value.height = document.querySelector(".body").clientHeight;
    ctx.value = canvas.value.getContext("2d");
    frontImg.value.onload = () => {};
  }
}

const onClickReference = () => {
  console.log("reference");
  showDialog.value = !showDialog.value;
  console.log(showDialog.value);
};

// 清除画布（重新设置画布的尺寸即可重新绘制画布，起到清空的目的）
function clearCanvas() {
  canvas.value.width = document.querySelector(".body").clientWidth;
  canvas.value.height = document.querySelector(".body").clientHeight;
}

/**
 * 绘制canvas时模拟图片的cover效果
 */
function simulateCover() {
  var imageRatio = imgWidth.value / imgHeight.value;
  var canvasRatio = width.value / height.value;
  console.log(width.value, height.value);
  if (imageRatio <= canvasRatio) {
    sw.value = imgWidth.value;
    sh.value = sw.value / canvasRatio;
    sx.value = 0;
    sy.value = (imgHeight.value - sh.value) / 2;
  } else {
    sh.value = imgHeight.value;
    sw.value = sh.value * canvasRatio;
    sx.value = (imgWidth.value - sw.value) / 2;
    sy.value = 0;
  }
}

const draw = () => {
  clearCanvas();
  ctx.value.beginPath();
  ctx.value.arc(touchX.value, touchY.value, circleSize.value, 0, Math.PI * 2, true);
  ctx.value.clip();
  ctx.value.drawImage(
    frontImg.value,
    sx.value,
    sy.value,
    sw.value,
    sh.value,
    0,
    0,
    width.value,
    height.value
  );
  console.log(sx.value, sy.value, sw.value, sh.value, 0, height.value, width.value, height.value);
  if (rightButton.value == 1) {
    ctx.value.strokeStyle = "#ccc";
  } else {
    ctx.value.strokeStyle = "#fff";
  }
  ctx.value.stroke();
};

(function () {
  if (typeof EventTarget !== "undefined") {
    let func = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function (type, fn, capture) {
      this.func = func;
      if (typeof capture !== "boolean") {
        capture = capture || {};
        capture.passive = false;
      }
      this.func(type, fn, capture);
    };
  }
})();

onMounted(() => {
  load();
});
</script>

<style lang="scss">
$primaryColor: #00a3df;

#wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  background-color: #f1f9fd;
  .header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      margin-top: 0.3rem;
      height: 70%;
    }
    .color {
      color: $primaryColor;
    }
    .grey {
      text-align: end;
      color: #7a7c79;
      font-size: 0.9rem;
    }
  }
  .body {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 2px solid $primaryColor;
    border-radius: 20px;
    z-index: 1000;
    .fg_mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      border-radius: 18px;
      background-color: rgba(0, 0, 0, 0.47);
    }
    .circle_desc {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      z-index: 1000;
      color: #fff;
      border: 1px solid #868686;
      transform: rotate(90deg);
      div {
        margin-top: 0.5rem;
      }
      img {
        margin-top: 1.1rem;
        height: 1.5rem;
      }
    }
  }
  .button {
    margin-top: -1px;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    .button-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      color: $primaryColor;
      border: 1px solid $primaryColor;
      border-top: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      &.active {
        color: #fff;
        background-color: $primaryColor;
        font-weight: bold;
      }
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        .t {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.4rem;
          height: 1.4rem;
          transform: rotate(90deg);
          font-size: 1rem;
        }
      }
    }
  }
}
.fg-wrapper {
  width: 100%;
  height: 100%;
  display: none;
  z-index: -1;
}
.foreground {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  border-radius: 17px;
  -webkit-border-radius: 17px;
}
.far_day {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  border-radius: 17px;
  -webkit-border-radius: 17px;
}
#canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.footer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0;
  color: #5e5e60;
  .left {
    display: flex;
    align-items: center;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    img {
      width: 90%;
    }
  }
  .reference {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: $primaryColor;
    text-decoration: underline;
    font-size: 0.9rem;
    min-width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    height: 100%;
    font-weight: bold;
  }
}
canvas {
  z-index: 1;
}
.panel {
  position: absolute;
  z-index: 1000;
  border: 0.16rem solid $primaryColor;
  background-color: hsla(196, 100%, 44%, 0.34);
  border-radius: 1.4rem;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  font-size: 1.2rem;
  transform: rotate(90deg);
  transform-origin: 0 0;
  .zhujing,
  .zhouwei {
    display: flex;
    align-items: center;
    width: 19rem;
  }
  .zhujing {
    margin: 0 1.4rem;
    margin-top: 0.7rem;
  }
  .zhouwei {
    margin: 0 1.4rem;
    margin-top: 0.7rem;
  }
  .label {
    color: $primaryColor;
    font-size: 1.2rem;
    font-weight: bold;
    min-width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
  }
  .panel_button {
    flex: 1;
    border: 1px solid $primaryColor;
    border-radius: 0.63rem;
    margin-right: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.4rem;
    background-color: #fff;
    color: $primaryColor;
    font-size: 1.4rem;
    &.panel_button_zhouwei {
      height: 2.4rem;
    }
    &.active {
      background-color: $primaryColor;
      color: #fff;
      font-weight: bold;
    }
  }
  .line {
    margin: 0 1.4rem;
    margin-top: 1rem;
    height: 0.05rem;
    background-color: $primaryColor;
  }
  .confirm {
    margin: 0 1.4rem;
    margin-top: 1rem;
    margin-bottom: 1.4rem;
    border: 1px solid $primaryColor;
    border-radius: 0.7rem;
    background-color: #fff;
    color: $primaryColor;
    font-size: 1.5rem;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    padding: 0.3rem 1.8rem;
  }
}
.panel_2 {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1000;
  padding: 1.2rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 1.3rem;
  transform: rotate(90deg);
  transform-origin: 0 0;
  .panel_button {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    border: 1px solid $primaryColor;
    border-radius: 0.6rem;
    margin-right: 0.4rem;
    height: 2.5rem;
    background-color: $primaryColor;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
  }

  .panel_button2 {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $primaryColor;
    border-radius: 0.6rem;
    margin-right: 0.4rem;
    width: 5.7rem;
    height: 2.5rem;
    background-color: #fff;
    color: $primaryColor;
    font-size: 1.4rem;
  }
}
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;
  .dialog_box {
    position: relative;
    padding: 3rem 4.3rem 3rem 2.2rem;
    background-color: #fff;
    transform: rotate(90deg);
    font-size: 1.1rem;
    border-radius: 1rem;
    .box_1 {
      display: flex;
    }
    img {
      position: absolute;
      top: 1.2rem;
      right: 1.2rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
.landscape {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f3f8fc;
  color: #a5aeb6;
  font-size: 0.8rem;
}
</style>
